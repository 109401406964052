@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap&subset=latin-ext);
body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  line-height: 17px;
  overflow: hidden;
}

#root {
  /* This needs to be set for react tutorial to work properly. See: https://github.com/gilbarbara/react-joyride/issues/467 */
  background-color: white;
}

strong {
  font-weight: 500;
}

a {
  color: #0085ff;
  text-decoration: none;
}

kbd {
  background-color: #e1e6ef;
  text-align: center;
  border-radius: 4px;
  height: 24px;
  padding: 0 8px;
  min-width: 24px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.8px;
  position: relative;
  margin: 0 6px;
  font-family: 'Rubik', sans-serif;
  display: inline-flex;
  justify-content: center;
  box-sizing: border-box;
}

.introjs-tooltip {
  padding: 20px;
}

::-webkit-scrollbar-track {
  width: 4px;
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 4px;
  background-color: #d6d6d6;
}

